/*eslint-disable*/

import {
  IoServerOutline,
  IoSpeedometerOutline,
  IoCashOutline,
  IoDocumentTextOutline,
} from 'react-icons/io5';
import Logo from '../assets/image/LogoPS.png';
import {
  // MASTER
  GroupAset,
  KondisiAset,
  KategoriAset,
  Pabrikan,
  AsetBangunan,
  AsetInventarisKantor,
  AsetKendaraan,
  AsetMesinDanAlat,
  AsetTanah,
  EntitasAset,
  JenisAset,
  DataAsetTanah,
  DataAsetBangunan,
  AsetSistem,
  DataAsetSistem,
  DataAsetKendaraan,
  DataAsetInventarisKantor,
  DataAsetMesinDanAlat,
  AsetLain,
  DataAsetLain,

  // TRANSAKSI
  // Transfer Produksi Alat Mesin
  ListTransferProduksiAlatMesin,
  ListPermintaanProduksiTransferAlatMesin,
  TransferProduksiAlatMesin,
  TransferAlatMesinJobMix,
  ListPermintaanAlatMesinJobMix,
  TambahTransferAlatMesinJobMix,

  // Transfer Alat Mesin Work Order
  ListPermintaanAlatMesinWorkOrder,
  TambahTransferAlatMesinWorkOrder,
  ListTransferAlatMesinWorkOrder,

  // Laporan
  LaporanPenyusutanAset,
  LaporanDaftarAset,
  LaporanRekapitulasiPenyusutanAset,
  ItemChecklistList,
  AnalisaChecklistList,
  ItemAsetAnalisaChecklist,
  AnalisaChecklistCreate,
  AnalisaChecklistUpdate,
  AnalisaChecklistDetail,
  MaintenanceRequest,
  MaintenanceRequestCreate,
  FormChecklistList,
  FormChecklistMaintenanceRequestList,
  FormChecklistCreate,
  MaintenanceRequestUpdate,
  MaintenanceRequestDetail,
  FormChecklistDetail,

  // Work Order
  ListWorkOrder,
  ListMaintenanceRequestWorkOrder,
  TambahWorkOrder,
  PermintaanWorkOrder,
  ListWorkOrderPermintaanWorkOrder,
  PermintaanWorkOrderCreate,

  // Realisasi Work Order
  ListRealisasiWorkOrder,
  ListWorkOrderRealisasiWorkOrder,
  TambahRealisasiWorkOrder,
  DetailRealisasiWorkOrder,

  // Close Work Order
  ListCloseWorkOrder,
  ListWorkOrderCloseWorkOrder,
  TambahCloseWorkOrder,
  PermintaanAsetList,
  PermintaanAsetCreate,
  PermintaanAsetUpdate,
  PermintaanAsetDetail,
  PenerimaanAsetList,
  PurchaseOrderPenerimaanAsetList,
  PenerimaanAsetAsetCreate,
  PenerimaanAsetDetail,
  PenerimaanAsetUpdate,
  TransferAlatMesinProduksiAsetList,
} from '../pages/ASM';

export default {
  LOGO: Logo,
  MODUL: 'ASET',

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['ASM'],
    },
    {
      text: 'Master Data',
      type: 'dropdown',
      icon: <IoServerOutline />,
      hak: ['ASM'],
      menu: [
        {
          text: 'Entitas Aset',
          link: '/asm/master/entitas-aset',
          hak: ['ASM', 'ASM_MAS_ENT'],
        },
        {
          text: 'Grup Aset',
          link: '#',
          hak: ['ASM'],
          menu: [
            {
              text: 'Aset Tanah',
              link: '/asm/master/item-aset/aset-tanah',
              hak: ['ASM', 'ASM_MAS_TNH', 'ASM_REG_TNH'],
            },
            {
              text: 'Aset Bangunan',
              link: '/asm/master/item-aset/aset-bangunan',
              hak: ['ASM', 'ASM_MAS_BGN', 'ASM_REG_BGN'],
            },
            {
              text: 'Aset Kendaraan',
              link: '/asm/master/item-aset/aset-kendaraan',
              hak: ['ASM', 'ASM_MAS_KDR', 'ASM_REG_KDR'],
            },
            {
              text: 'Aset Alat dan Mesin',
              link: '/asm/master/item-aset/aset-alat-dan-mesin',
              hak: ['ASM', 'ASM_MAS_ALM', 'ASM_REG_ALM'],
            },
            {
              text: 'Aset Inventaris',
              link: '/asm/master/item-aset/aset-inventaris-kantor',
              hak: ['ASM', 'ASM_MAS_IVK', 'ASM_REG_IVK'],
            },
            {
              text: 'Aset Sistem-sistem',
              link: '/asm/master/item-aset/aset-sistem',
              hak: ['ASM', 'ASM_MAS_ASS', 'ASM_REG_STM'],
            },
            {
              text: 'Aset Lainnya',
              link: '/asm/master/item-aset/aset-lain',
              hak: ['ASM', 'ASM_MAS_ASL', 'ASM_REG_ALN'],
            },
          ],
        },
        {
          text: 'Kategori Aset',
          link: '/asm/master/kategori-aset',
          hak: ['ASM', 'ASM_MAS_KTG'],
        },
        {
          text: 'Jenis Aset',
          link: '/asm/master/jenis-aset',
          hak: ['ASM', 'ASM_MAS_JEN'],
        },
        {
          text: 'Kondisi Aset',
          link: '/asm/master/kondisi-aset',
          hak: ['ASM', 'ASM_MAS_KDA'],
        },
        {
          text: 'Pabrikan Aset',
          link: '/asm/master/pabrikan',
          hak: ['ASM', 'ASM_MAS_PAB'],
        },
        {
          text: 'Item Checklist',
          link: '/asm/master/item-checklist',
          hak: ['ASM', 'ASM_MAS_ICL'],
        },
        {
          text: 'Analisa Checklist',
          link: '/asm/master/analisa-checklist',
          hak: ['ASM', 'ASM_MAS_ACL'],
        },
      ],
    },
    {
      text: 'Transaksi',
      type: 'dropdown',
      icon: <IoCashOutline />,
      hak: ['SUPA', 'ASM'],
      menu: [
        {
          text: 'Transfer Alat Mesin Produksi',
          link: '/transaksi/transfer-alat-mesin-job-mix',
          hak: ['SUPA', 'ASM_TRN_TPA'],
        },
        {
          text: 'Maintenance Request',
          link: '/transaksi/maintenance-request',
          hak: ['SUPA', 'ASM_TRN_MTR'],
        },
        {
          text: 'Form Checklist',
          link: '/transaksi/form-checklist',
          hak: ['SUPA', 'ASM_TRN_FOC'],
        },
        {
          text: 'Work Order',
          link: '/transaksi/work-order',
          hak: ['SUPA', 'ASM_TRN_WOR'],
        },
        {
          text: 'Permintaan Work Order',
          link: '/transaksi/permintaan-work-order',
          hak: ['SUPA', 'ASM_TRN_PWO'],
        },
        {
          text: 'Transfer Alat Mesin Work Order',
          link: '/transaksi/transfer-alat-mesin-work-order',
          hak: ['SUPA', 'ASM_TRN_TWA'],
        },
        {
          text: 'Realisasi Work Order',
          link: '/transaksi/realisasi-work-order',
          hak: ['SUPA', 'ASM_TRN_RWO'],
        },
        {
          text: 'Close Work Order',
          link: '/transaksi/close-work-order',
          hak: ['SUPA', 'ASM_TRN_CWO'],
        },
        {
          text: 'Permintaan Aset',
          link: '/transaksi/permintaan-aset',
          hak: ['SUPA', 'ASM_TRN_PAT'],
        },
        {
          text: 'Penerimaan Aset',
          link: '/transaksi/penerimaan-aset',
          hak: ['SUPA', 'ASM_TRN_PAT'],
        },
        {
          text: 'Transfer Alat Mesin Produksi Aset',
          link: '/transaksi/transfer-alat-mesin-produksi-aset',
          hak: ['SUPA', 'ASM_TRN_TAPA'],
        },
      ],
    },
    {
      text: 'Laporan',
      type: 'dropdown',
      icon: <IoDocumentTextOutline />,
      hak: ['SUPA', 'ASM'],
      menu: [
        {
          text: 'Laporan Penyusutan Aset',
          link: '/laporan/laporan-penyusutan-aset',
          hak: ['SUPA', 'ASM_RPT_LPA'],
        },
        {
          text: 'Rekapitulasi Penyusutan Aset',
          link: '/laporan/laporan-rekapitulasi-penyusutan-aset',
          hak: ['SUPA', 'ASM_RPT_RNPA'],
        },
      ],
    },

    //{
    //   text: "Profil",
    //   type: "dropdown",
    //   icon: <IoPersonCircleOutline />,
    //   hak: ["ASM"],
    //   menu: [
    //     {
    //       text: "Akun Saya",
    //       link: "/profil",
    //       hak: ["ASM"],
    //     },
    //     {
    //       text: "Ubah Akun",
    //       link: "/profil/ubah",
    //       hak: ["ASM"],
    //     },
    //     {
    //       text: "Ganti Password",
    //       link: "/profil/ganti-password",
    //       hak: ["ASM"],
    //     },
    //   ],
    // },
  ],

  ROUTES: [
    // MASTER
    {
      exact: true,
      path: '/asm/master/grup-aset',
      page: GroupAset,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/entitas-aset',
      page: EntitasAset,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/kondisi-aset',
      page: KondisiAset,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/jenis-aset',
      page: JenisAset,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/kategori-aset',
      page: KategoriAset,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/pabrikan',
      page: Pabrikan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-tanah',
      page: AsetTanah,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-tanah/:tipe',
      page: DataAsetTanah,
      hak: ['ASM', 'ASM_REG_TNH'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-tanah/:tipe/:id',
      page: DataAsetTanah,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-bangunan',
      page: AsetBangunan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-bangunan/:tipe',
      page: DataAsetBangunan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-bangunan/:tipe/:id',
      page: DataAsetBangunan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-sistem',
      page: AsetSistem,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-sistem/:tipe',
      page: DataAsetSistem,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-sistem/:tipe/:id',
      page: DataAsetSistem,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-kendaraan',
      page: AsetKendaraan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-kendaraan/:tipe',
      page: DataAsetKendaraan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-kendaraan/:tipe/:id',
      page: DataAsetKendaraan,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-alat-dan-mesin',
      page: AsetMesinDanAlat,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-alat-dan-mesin/:tipe',
      page: DataAsetMesinDanAlat,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-alat-dan-mesin/:tipe/:id',
      page: DataAsetMesinDanAlat,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-inventaris-kantor',
      page: AsetInventarisKantor,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-inventaris-kantor/:tipe',
      page: DataAsetInventarisKantor,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-inventaris-kantor/:tipe/:id',
      page: DataAsetInventarisKantor,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-lain',
      page: AsetLain,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-lain/:tipe',
      page: DataAsetLain,
      hak: ['ASM'],
    },
    {
      exact: true,
      path: '/asm/master/item-aset/aset-lain/:tipe/:id',
      page: DataAsetLain,
      hak: ['ASM'],
    },

    {
      exact: true,
      path: '/asm/master/item-checklist',
      page: ItemChecklistList,
      hak: ['ASM', 'ASM_MAS_ICL'],
    },

    {
      exact: true,
      path: '/asm/master/analisa-checklist',
      page: AnalisaChecklistList,
      hak: ['ASM', 'ASM_MAS_ACL'],
    },

    {
      exact: true,
      path: '/asm/master/analisa-checklist/item-aset',
      page: ItemAsetAnalisaChecklist,
      hak: ['ASM'],
    },

    {
      exact: true,
      path: '/asm/master/analisa-checklist/item-aset/tambah/:id',
      page: AnalisaChecklistCreate,
      hak: ['ASM'],
    },

    {
      exact: true,
      path: '/asm/master/analisa-checklist/ubah/:id',
      page: AnalisaChecklistUpdate,
      hak: ['ASM'],
    },

    {
      exact: true,
      path: '/asm/master/analisa-checklist/detail/:id',
      page: AnalisaChecklistDetail,
      hak: ['ASM'],
    },

    // TRANSAKSI
    // Transfer Produksi Alat Mesin
    // {
    //   exact: true,
    //   path: "/transaksi/transfer-produksi-alat-mesin",
    //   page: ListTransferProduksiAlatMesin,
    //   hak: ["SUPA", "ASM_TRN_TPA"],
    // },
    // {
    //   exact: true,
    //   path: "/transaksi/transfer-produksi-alat-mesin/permintaan",
    //   page: ListPermintaanProduksiTransferAlatMesin,
    //   hak: ["SUPA", "ASM_TRN_TPA"],
    // },
    // {
    //   exact: true,
    //   path: "/transaksi/transfer-produksi-alat-mesin/transfer/:id",
    //   page: TransferProduksiAlatMesin,
    //   hak: ["SUPA", "ASM_TRN_TPA"],
    // },

    // TRANSAKSI

    // Transfer AlatMesin Job Mix
    {
      exact: true,
      path: '/transaksi/transfer-alat-mesin-job-mix',
      page: TransferAlatMesinJobMix,
      hak: ['SUPA', 'ASM_TRN_TPA'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-alat-mesin-job-mix/list-permintaan-alat-mesin',
      page: ListPermintaanAlatMesinJobMix,
      hak: ['SUPA', 'ASM_TRN_TPA'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-alat-mesin-job-mix/tambah/:id',
      page: TambahTransferAlatMesinJobMix,
      hak: ['SUPA', 'ASM_TRN_TPA'],
    },

    // Work Order
    {
      exact: true,
      path: '/transaksi/work-order',
      page: ListWorkOrder,
      hak: ['SUPA', 'ASM_TRN_WOR'],
    },
    {
      exact: true,
      path: '/transaksi/work-order/list-maintenance-request',
      page: ListMaintenanceRequestWorkOrder,
      hak: ['SUPA', 'ASM_TRN_WOR'],
    },
    {
      exact: true,
      path: '/transaksi/work-order/tambah/:id_maintenance_request_master',
      page: TambahWorkOrder,
      hak: ['SUPA', 'ASM_TRN_WOR'],
    },

    // Permintaan Work Order
    {
      exact: true,
      path: '/transaksi/permintaan-work-order',
      page: PermintaanWorkOrder,
      hak: ['SUPA', 'ASM_TRN_PWO'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-work-order/work-order',
      page: ListWorkOrderPermintaanWorkOrder,
      hak: ['SUPA', 'ASM_TRN_PWO'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-work-order/work-order/tambah/:id',
      page: PermintaanWorkOrderCreate,
      hak: ['SUPA', 'ASM_TRN_PWO'],
    },

    {
      exact: true,
      path: '/transaksi/work-order/list-maintenance-request',
      page: ListMaintenanceRequestWorkOrder,
      hak: ['SUPA', 'ASM_TRN_WO'],
    },
    {
      exact: true,
      path: '/transaksi/work-order/tambah/:id_maintenance_request_master',
      page: TambahWorkOrder,
      hak: ['SUPA', 'ASM_TRN_WO'],
    },

    // Transfer Alat Mesin Work Order
    {
      exact: true,
      path: '/transaksi/transfer-alat-mesin-work-order',
      page: ListTransferAlatMesinWorkOrder,
      hak: ['SUPA', 'ASM_TRN_TWA'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-alat-mesin-work-order/list-permintaan-work-order',
      page: ListPermintaanAlatMesinWorkOrder,
      hak: ['SUPA', 'ASM_TRN_TWA'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-alat-mesin-work-order/tambah/:id_work_order',
      page: TambahTransferAlatMesinWorkOrder,
      hak: ['SUPA', 'ASM_TRN_TWA'],
    },

    // Maintenance Request
    {
      exact: true,
      path: '/transaksi/maintenance-request',
      page: MaintenanceRequest,
      hak: ['SUPA', 'ASM_TRN_MTR'],
    },
    {
      exact: true,
      path: '/transaksi/maintenance-request/tambah',
      page: MaintenanceRequestCreate,
      hak: ['SUPA', 'ASM_TRN_MTR'],
    },
    {
      exact: true,
      path: '/transaksi/maintenance-request/ubah/:id',
      page: MaintenanceRequestUpdate,
      hak: ['SUPA', 'ASM_TRN_MTR'],
    },
    {
      exact: true,
      path: '/transaksi/maintenance-request/detail/:id',
      page: MaintenanceRequestDetail,
      hak: ['SUPA', 'ASM_TRN_MTR'],
    },

    // Form Checklist
    {
      exact: true,
      path: '/transaksi/form-checklist',
      page: FormChecklistList,
      hak: ['SUPA', 'ASM_TRN_FOC'],
    },

    {
      exact: true,
      path: '/transaksi/form-checklist/maintenance-request',
      page: FormChecklistMaintenanceRequestList,
      hak: ['SUPA', 'ASM_TRN_FOC'],
    },

    {
      exact: true,
      path: '/transaksi/form-checklist/maintenance-request/tambah/:id',
      page: FormChecklistCreate,
      hak: ['SUPA', 'ASM_TRN_FOC'],
    },

    {
      exact: true,
      path: '/transaksi/form-checklist/detail/:id',
      page: FormChecklistDetail,
      hak: ['SUPA', 'ASM_TRN_FOC'],
    },

    // Realisasi Work Order
    {
      exact: true,
      path: '/transaksi/realisasi-work-order',
      page: ListRealisasiWorkOrder,
      hak: ['SUPA', 'ASM_TRN_RWO'],
    },
    {
      exact: true,
      path: '/transaksi/realisasi-work-order/list-work-order',
      page: ListWorkOrderRealisasiWorkOrder,
      hak: ['SUPA', 'ASM_TRN_RWO'],
    },
    {
      exact: true,
      path: '/transaksi/realisasi-work-order/tambah/:id_work_order',
      page: TambahRealisasiWorkOrder,
      hak: ['SUPA', 'ASM_TRN_RWO'],
    },
    {
      exact: true,
      path: '/transaksi/realisasi-work-order/detail/:id_realisasi_work_order',
      page: DetailRealisasiWorkOrder,
      hak: ['SUPA', 'ASM_TRN_RWO'],
    },

    // Close Work Order
    {
      exact: true,
      path: '/transaksi/close-work-order',
      page: ListCloseWorkOrder,
      hak: ['SUPA', 'ASM_TRN_CWO'],
    },
    {
      exact: true,
      path: '/transaksi/close-work-order/list-work-order',
      page: ListWorkOrderCloseWorkOrder,
      hak: ['SUPA', 'ASM_TRN_CWO'],
    },
    {
      exact: true,
      path: '/transaksi/close-work-order/tambah/:id_work_order',
      page: TambahCloseWorkOrder,
      hak: ['SUPA', 'ASM_TRN_CWO'],
    },

    // Permintaan Aset
    {
      exact: true,
      path: '/transaksi/permintaan-aset',
      page: PermintaanAsetList,
      hak: ['SUPA', 'ASM_TRN_PAT'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-aset/tambah',
      page: PermintaanAsetCreate,
      hak: ['SUPA', 'ASM_TRN_PAT'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-aset/ubah/:id',
      page: PermintaanAsetUpdate,
      hak: ['SUPA', 'ASM_TRN_PAT'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-aset/detail/:id',
      page: PermintaanAsetDetail,
      hak: ['SUPA', 'ASM_TRN_PAT'],
    },

    // Penerimaan Aset
    {
      exact: true,
      path: '/transaksi/penerimaan-aset',
      page: PenerimaanAsetList,
      hak: ['SUPA', 'ASM_TRN_PAT'],
    },
    {
      exact: true,
      path: '/transaksi/penerimaan-aset/purchase-order',
      page: PurchaseOrderPenerimaanAsetList,
      hak: ['SUPA', 'ASM_TRN_PAT'],
    },
    {
      exact: true,
      path: '/transaksi/penerimaan-aset/tambah/:id',
      page: PenerimaanAsetAsetCreate,
      hak: ['SUPA', 'ASM_TRN_PAT'],
    },
    {
      exact: true,
      path: '/transaksi/penerimaan-aset/ubah/:id',
      page: PenerimaanAsetUpdate,
      hak: ['SUPA', 'ASM_TRN_PAT'],
    },
    {
      exact: true,
      path: '/transaksi/penerimaan-aset/detail/:id',
      page: PenerimaanAsetDetail,
      hak: ['SUPA', 'ASM_TRN_PAT'],
    },

    // LAPORAN
    {
      exact: true,
      path: '/laporan/laporan-penyusutan-aset',
      page: LaporanPenyusutanAset,
      hak: ['SUPA', 'ASM', 'ASM_RPT_RNPA'],
    },
    {
      exact: true,
      path: '/laporan/laporan-rekapitulasi-penyusutan-aset',
      page: LaporanRekapitulasiPenyusutanAset,
      hak: ['SUPA', 'ASM', 'ASM_RPT_LPA'],
    },
    {
      exact: true,
      path: '/laporan/laporan-daftar-aset',
      page: LaporanDaftarAset,
      hak: ['SUPA', 'ASM', 'ASM_RPT_LDA'],
    },

    // Transfer Alat Mesin Produksi Aset
    {
      exact: true,
      path: '/transaksi/transfer-alat-mesin-produksi-aset',
      page: TransferAlatMesinProduksiAsetList,
      hak: ['SUPA', 'ASM_TRN_TAPA'],
    },
  ],
};
